import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as moment from "moment";
import "moment/locale/fr";
import "./LiveCard.styles.scss";
import { InlineIcon } from "@iconify/react";
import { SmartDisplayRounded, PlayArrowRounded } from "@mui/icons-material";
import { Box, Button, Popper } from "@mui/material";
import TextDisplayer from "@/components/TextDisplayer/TextDisplayer";

export type TLiveCard = {
  _id: string;
  title: string;
  host: string;
  type: "à venir" | "passés" | "En direct" | "";
  link: string;
  note: string;
  live_date: Date;
};

const LiveCard: React.FC<TLiveCard> = ({
  _id,
  title,
  host,
  type,
  link,
  note,
  live_date,
}) => {
  const future_date = moment.default(live_date);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <div className="card-live">
      <div className="head-part">
        <div className="img-container">
          {type === "passés" && (
            <>
              <img
                src={require("@/assets/images/png/live-off.png")}
                alt="logo-mini"
              />
              <small className="past">Terminé</small>
            </>
          )}
          {type === "En direct" && (
            <>
              <img
                src={require("@/assets/images/gif/live-1.gif")}
                alt="logo-mini"
              />
              <small className="now">En direct</small>
            </>
          )}
          {type === "à venir" && (
            <>
              <span className="corner">
                Dans&nbsp;
                <b>
                  {future_date.diff(moment.default(new Date()), "days") +
                    " jrs"}
                </b>
              </span>
              <img
                src={require("@/assets/images/png/live-next.png")}
                alt="logo-mini"
              />
              <small className="futur">À venir</small>
            </>
          )}
        </div>

        <div className="middle-container">
          <PerfectScrollbar className="head-text">
            <h3> {title} </h3>
            <p>
              {" "}
              Tenu par : <b>{host}</b>{" "}
            </p>
            <TextDisplayer
              aria-describedby={id}
              onMouseEnter={handleMouseEnter}
              className="note"
              value={note}
            />
            {/* <p
              className="note"
            >
              {" "}
              Note : <>{note}</>
            </p> */}
          </PerfectScrollbar>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 10 }}
            onMouseLeave={handleMouseLeave}
          >
            <TextDisplayer className="tooltip-live-note" value={note} />
          </Popper>
          <div className="time-part">
            <div className="date">
              <InlineIcon icon="bx:calendar" />
              &nbsp;
              <span>
                {" "}
                {moment.default(live_date).format("L")}{" "}
                {moment.default(live_date).format("LT")}{" "}
              </span>
              {type === "passés" && (
                <Button
                  className="btn-link"
                  color="inherit"
                  endIcon={<PlayArrowRounded />}
                  variant="contained"
                  href={link || "https://zawaj-sounnah.com/"}
                  target="_blank"
                >
                  Revoir
                </Button>
              )}
              {type === "En direct" && (
                <Button
                  className="btn-link"
                  color="error"
                  endIcon={<SmartDisplayRounded />}
                  variant="contained"
                  href={link || "https://zawaj-sounnah.com/"}
                  target="_blank"
                >
                  Rejoindre
                </Button>
              )}
              {type === "à venir" && (
                <span className="corner">
                  dans&nbsp;
                  <b>
                    {future_date.diff(moment.default(new Date()), "days") +
                      " jrs"}
                  </b>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {type === "passés" && (
        <Button
          color="inherit"
          className="sm-btn"
          endIcon={<PlayArrowRounded />}
          variant="contained"
        >
          Revoir
        </Button>
      )}
      {type === "En direct" && (
        <Button
          color="error"
          className="sm-btn"
          endIcon={<SmartDisplayRounded />}
          variant="contained"
        >
          Rejoindre
        </Button>
      )}
    </div>
  );
};

export default LiveCard;
